import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { CssGrid } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cssgrid"
    }}>{`CssGrid`}</h1>
    <p>{`Component for creating css grid layouts. All possible props are listed in `}<a parentName="p" {...{
        "href": "https://styled-system.com/api#grid-layout"
      }}>{`styled-system`}</a></p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<CssGrid\n  gridRowGap={30}\n  gridColumnGap={30}\n  gridTemplateRows=\"1fr 1fr 1fr\"\n  gridTemplateColumns=\"1fr 1fr 1fr\"\n>\n  <div>Column 1</div>\n  <div>Column 2</div>\n  <div>Column 3</div>\n  <div>Column 4</div>\n  <div>Column 5</div>\n  <div>Column 6</div>\n</CssGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CssGrid,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CssGrid gridRowGap={30} gridColumnGap={30} gridTemplateRows="1fr 1fr 1fr" gridTemplateColumns="1fr 1fr 1fr" mdxType="CssGrid">
    <div>Column 1</div>
    <div>Column 2</div>
    <div>Column 3</div>
    <div>Column 4</div>
    <div>Column 5</div>
    <div>Column 6</div>
  </CssGrid>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      